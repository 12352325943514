import axios from 'axios';
import * as dayjs from 'dayjs';
import {
  GET_PAST_REVIEWS,
  GET_REVIEWS,
  REVIEWS,
  UPDATE_PAST_REVIEW_EXPIRATION_TIME,
  UPDATE_REVIEW_EXPIRATION_TIME,
} from './types';
import { setAlert } from './alert';
import { read, write } from '../utils/cache';

export const getReviews = (parameters) => async (dispatch) => {
  try {
    const reviews = read(
      `${REVIEWS}-${parameters.currentYear}-${parameters.currentWeek}`
    );
    const timeCreation = Date.now();
    const res = !reviews
      ? await axios.get(
          `${process.env.REACT_APP_URL_BACKEND}/reviews/${parameters.currentYear}/${parameters.currentWeek}`
        )
      : reviews;
    if (!reviews) {
      res.time = timeCreation;
      write(
        `${REVIEWS}-${parameters.currentYear}-${parameters.currentWeek}`,
        res
      );
    }
    dispatch({
      type: UPDATE_REVIEW_EXPIRATION_TIME,
      payload: !reviews ? timeCreation : res.time,
    });
    const data = {
      reviews: res.data.items,
    };
    dispatch({
      type: GET_REVIEWS,
      payload: data.reviews,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};
export const getPastReviews = (parameters) => async (dispatch) => {
  try {
    const reviews = read(`PAST-${REVIEWS}`);
    const timeCreation = Date.now();
    const res = !reviews
      ? await axios.get(`${process.env.REACT_APP_URL_BACKEND}/past-reviews`)
      : reviews;
    if (!reviews) {
      res.time = timeCreation;
      write(`PAST-${REVIEWS}`, res);
    }
    dispatch({
      type: UPDATE_PAST_REVIEW_EXPIRATION_TIME,
      payload: !reviews ? timeCreation : res.time,
    });
    const data = {
      reviews: res.data.items,
    };
    dispatch({
      type: GET_PAST_REVIEWS,
      payload: data.reviews,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};
export const getReview = (parameters) => async (dispatch) => {
  try {
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};
