import { GET_REVIEWS, GET_REVIEW } from '../actions/types';

const initialState = {
  review: {},
  reviews: [],
  loadingReview: true,
  error: {},
};

export default function reviews(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_REVIEWS:
      return {
        ...state,
        review: {},
        reviews: payload,
        loadingReview: false,
      };
    case GET_REVIEW:
      return {
        ...state,
        review: payload,
        reviews: [],
        loadingReview: false,
      };
    default:
      return state;
  }
}
