export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const GET_REVIEWS = 'GET_REVIEWS';
export const GET_REVIEW = 'GET_REVIEW';

export const GET_PAST_REVIEWS = 'GET_PAST_REVIEWS';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const REVIEWS = 'REVIEWS';

export const UPDATE_REVIEW_EXPIRATION_TIME = 'UPDATE_REVIEW_EXPIRATION_TIME';
export const UPDATE_PAST_REVIEW_EXPIRATION_TIME =
  'UPDATE_PAST_REVIEW_EXPIRATION_TIME';
