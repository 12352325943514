import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { setAlert, removeAlert } from '../../actions/alert';

import Cache from '../elements/Cache';
import Filter from '../elements/Filter';

import { getReviews, getPastReviews } from '../../actions/reviews';
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

const Dashboard = ({
  reviews: { reviews, loadingReview },
  pastReviews: { pastReviews, loadingPastReviews },
  setAlert,
  removeAlert,
  getReviews,
  getPastReviews,
}) => {
  const currentYear = dayjs(Date.now()).format('YYYY');
  const currentWeek = dayjs().week();
  useEffect(() => {
    setAlert('Get customer data', 'info');
    getReviews({ currentYear: currentYear, currentWeek: currentWeek });
  }, [getReviews]);
  useEffect(() => {
    setAlert('Get customer data', 'info');
    getPastReviews();
  }, [getPastReviews]);
  removeAlert({ source: 'dashboard' });
  const reviewItems = reviews.map((review) => {
    return {
      id: `${review.PK}#${review.SK}`,
      name: review.name,
      dateAdded: dayjs(parseInt(review.dateAdded)).format(
        'DD-MM-YYYY HH:mm:ss'
      ),
      email: review.email,
      url: review.url,
      dateEmailSent: review.dateEmailSent
        ? dayjs(parseInt(review.dateEmailSent)).format('DD-MM-YYYY HH:mm:ss')
        : null,
      result: review.result ? review.result[0] : null,
    };
  });

  const pastReviewItems = pastReviews.map((review) => {
    return {
      id: `${review.PK}#${review.SK}`,
      name: review.name,
      dateAdded: new Date(parseInt(review.dateAdded)),
      email: review.email,
      url: review.url,
      dateEmailSent: review.dateEmailSent
        ? new Date(parseInt(review.dateEmailSent))
        : null,
      result: review.result ? review.result[0] : null,
    };
  });

  const columns = [
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      width: 175,
      headerAlign: 'center',
      type: 'date',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 175,
      headerAlign: 'center',
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 175,
      headerAlign: 'center',
    },
    {
      field: 'url',
      headerName: 'Trello URL',
      width: 250,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <a href={params.value} target='_blank' rel='noreferrer'>
            {params.value}
          </a>
        );
      },
    },
    {
      field: 'dateEmailSent',
      headerName: 'Email sent',
      width: 175,
      headerAlign: 'center',
      type: 'date',
    },
    {
      field: 'result',
      headerName: 'Email Status',
      width: 150,
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.value) {
          return `${params.value.status}`;
        }
        return null;
      },
    },
  ];

  const [sortModel, setSortModel] = useState([
    {
      field: 'dateAdded',
      sort: 'desc',
    },
  ]);

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Fragment>{children}</Fragment>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return !loadingPastReviews && !loadingReview ? (
    <Fragment>
      <Cache></Cache>
      {/* <Filter  */}
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleTabChange}
          indicatorColor='secondary'
          textColor='inherit'
          aria-label='Customer Review Tab'
        >
          <Tab label='Current Week Request' {...a11yProps(0)}></Tab>
          <Tab label='Past Request' {...a11yProps(1)}></Tab>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div style={{ height: 400, width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={reviewItems}
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
              />
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div style={{ height: 400, width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={pastReviewItems}
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
              />
            </div>
          </div>
        </div>
      </TabPanel>
    </Fragment>
  ) : (
    <LinearProgress color='secondary' />
  );
};

Dashboard.propTypes = {
  setAlert: PropTypes.func.isRequired,
  removeAlert: PropTypes.func.isRequired,
  getReviews: PropTypes.func.isRequired,
  getPastReviews: PropTypes.func.isRequired,
  reviews: PropTypes.object.isRequired,
  pastReviews: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  reviews: state.reviews,
  pastReviews: state.pastReviews,
});

export default connect(mapStateToProps, {
  setAlert,
  removeAlert,
  getReviews,
  getPastReviews,
})(Dashboard);
