import { combineReducers } from 'redux';

import alert from './alert';
import auth from './auth';
import cache from './cache';
import reviews from './reviews';
import pastReviews from './pastReviews';

export default combineReducers({
  alert,
  auth,
  cache,
  pastReviews,
  reviews,
});
