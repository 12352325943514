import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import ReactGA from 'react-ga4';

import CssBaseline from '@mui/material/CssBaseline';
import { alpha } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

// ReactGA.initialize(process.env.REACT_APP_TRACKING_CODE);
// ReactGA.send('pageview');

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline></CssBaseline>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
