import {
  UPDATE_REVIEW_EXPIRATION_TIME,
  UPDATE_PAST_REVIEW_EXPIRATION_TIME,
} from '../actions/types';

const initialState = {
  review: {},
  pastReview: {},
};

export default function cache(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_REVIEW_EXPIRATION_TIME:
      return {
        ...state,
        review: payload,
      };
    case UPDATE_PAST_REVIEW_EXPIRATION_TIME:
      return {
        ...state,
        pastReview: payload,
      };
    default:
      return state;
  }
}
