import { GET_PAST_REVIEWS } from '../actions/types';

const initialState = {
  pastReview: {},
  pastReviews: [],
  loadingPastReview: true,
  error: {},
};

export default function pastReviews(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PAST_REVIEWS:
      return {
        ...state,
        pastReview: {},
        pastReviews: payload,
        loadingPastReview: false,
      };

    default:
      return state;
  }
}
