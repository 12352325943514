import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Can from '../routing/Can';

const drawerWidth = 280;

const DrawerCustom = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
}));
const DrawerContainer = styled(`div`)(({ theme }) => ({
  overflow: 'auto',
}));
const RootListCustom = styled(List)(({ theme }) => ({
  width: '100%',
  maxWidth: 360,
  backgroundColor: theme.palette.background.paper,
}));
const TypographyCustom2 = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));
const TypographyCustom = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '0.875rem',
  color: '#0000008a',
}));
const ListItemCustom = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}));

const VerticalNav = ({ auth: { isAuthenticated, loading, user } }) => {
  const location = useLocation();
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openHr, setOpenHr] = useState(true);
  const [openRecruitment, setOpenRecruitment] = useState(false);
  const [openRecruitmentSettings, setOpenRecruitmentSettings] = useState(false);
  const [openPL, setOpenPL] = useState(true);
  const [openBook, setOpenBook] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openProfile, setOpenProfile] = useState(true);
  const [openAbsence, setOpenAbsence] = useState(false);
  const [openCover, setOpenCover] = useState(false);
  const [openDesignChallenges, setOpenDesignChallenges] = useState(false);
  const [openCoverSettings, setOpenCoverSettings] = useState(false);
  const [openDesignChallengeSettings, setOpenDesignChallengeSettings] =
    useState(false);

  const handleClick = (type) => {
    switch (type) {
      case 'general':
        setOpenGeneral(!openGeneral);
        break;
      case 'hr':
        setOpenHr(!openHr);
        break;
      case 'recruitment':
        setOpenRecruitment(!openRecruitment);
        break;
      case 'recruitmentSettings':
        setOpenRecruitmentSettings(!openRecruitmentSettings);
        break;
      case 'pl':
        setOpenPL(!openPL);
        break;
      case 'book':
        setOpenBook(!openBook);
        break;
      case 'settings':
        setOpenSettings(!openSettings);
        break;
      case 'profile':
        setOpenProfile(!openProfile);
        break;
      case 'absence':
        setOpenAbsence(!openAbsence);
        break;
      case 'cover':
        setOpenCover(!openCover);
        break;
      case 'coverSettings':
        setOpenCoverSettings(!openCoverSettings);
        break;
      case 'designChallenge':
        setOpenDesignChallenges(!openDesignChallenges);
        break;
      case 'designChallengeSettings':
        setOpenDesignChallengeSettings(!openDesignChallengeSettings);
        break;
      default:
        break;
    }
  };
  return (
    <Fragment>
      {!loading && (
        <DrawerCustom variant='permanent'>
          <Toolbar />
          <DrawerContainer>
            <RootListCustom
              component='nav'
              aria-labelledby='nested-list-subheader'
            >
              <ListItem
                dense
                button
                component='a'
                href={process.env.REACT_APP_URL_PANEL}
                divider
              >
                <ListItemText
                  disableTypography
                  primary={
                    <TypographyCustom2 type='body2'>
                      Dashboard
                    </TypographyCustom2>
                  }
                />
              </ListItem>
              <ListItem
                dense
                button
                onClick={(e) => handleClick('general')}
                divider
              >
                <ListItemText
                  disableTypography
                  primary={
                    <TypographyCustom2 type='body2'>
                      General Tools
                    </TypographyCustom2>
                  }
                />
                {openGeneral ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openGeneral} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItemCustom
                    dense
                    button
                    component='a'
                    href={process.env.REACT_APP_URL_LINK_AWS}
                  >
                    <ListItemText primary='Link Shortener' />
                  </ListItemCustom>
                  <ListItemCustom dense button divider>
                    <ListItemText primary='PPP (Disabled)' />
                  </ListItemCustom>
                </List>
              </Collapse>
              <ListItem dense button onClick={(e) => handleClick('hr')} divider>
                <ListItemText
                  disableTypography
                  primary={
                    <TypographyCustom2 type='body2'>
                      Human Resources
                    </TypographyCustom2>
                  }
                />
                {openHr ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openHr} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItem
                    dense
                    button
                    onClick={(e) => handleClick('absence')}
                    divider
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <TypographyCustom type='body2'>
                          Absence
                        </TypographyCustom>
                      }
                    />
                    {openAbsence ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openAbsence} timeout='auto' unmountOnExit>
                    <ListItemCustom
                      dense
                      button
                      component='a'
                      href={process.env.REACT_APP_URL_TAP}
                    >
                      <ListItemText primary='Absence (Tap In Tools)' />
                    </ListItemCustom>
                    <Can
                      role={user.role}
                      perform='peoplem:visit'
                      yes={() => (
                        <ListItemCustom
                          dense
                          button
                          component='a'
                          href={process.env.REACT_APP_URL_MANAGER}
                          divider
                        >
                          <ListItemText primary='Team Absence' />
                        </ListItemCustom>
                      )}
                      no={() => ''}
                    />
                  </Collapse>
                  <Can
                    role={user.role}
                    perform='disc:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handleClick('recruitment')}
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <TypographyCustom type='body2'>
                                Recruitment
                              </TypographyCustom>
                            }
                          />
                          {openRecruitment ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse
                          in={openRecruitment}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_JOIN}dashboard`}
                          >
                            <ListItemText primary='Join' />
                          </ListItemCustom>
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            divider
                            href={`${process.env.REACT_APP_URL_DISC_TEST}?tko=jangansoktau`}
                          >
                            <ListItemText primary='DISC Test' />
                          </ListItemCustom>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <ListItemCustom
                    dense
                    button
                    component='a'
                    href={`${process.env.REACT_APP_URL_PANEL}address`}
                  >
                    <ListItemText primary='Address Listing' />
                  </ListItemCustom>
                  <Can
                    role={user.role}
                    perform='kucing:visit'
                    yes={() => (
                      <ListItemCustom
                        dense
                        button
                        component='a'
                        href={process.env.REACT_APP_URL_KUCING}
                        divider
                      >
                        <ListItemText primary='List Pertanyaan Kucing' />
                      </ListItemCustom>
                    )}
                    no={() => ''}
                  />
                </List>
              </Collapse>
              <ListItem dense button onClick={(e) => handleClick('pl')} divider>
                <ListItemText
                  disableTypography
                  primary={
                    <TypographyCustom2 type='body2'>
                      Paperlust Tools
                    </TypographyCustom2>
                  }
                />
                {openPL ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openPL} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <Can
                    role={user.role}
                    perform='cover:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handleClick('cover')}
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <TypographyCustom type='body2'>
                                Order Metrics Calculation
                              </TypographyCustom>
                            }
                          />
                          {openCover ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openCover} timeout='auto' unmountOnExit>
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}dashboard`}
                          >
                            <ListItemText primary='Custom Order - Turn Over Time' />
                          </ListItemCustom>
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}cards`}
                            divider
                          >
                            <ListItemText primary='Custom Order - Cards' />
                          </ListItemCustom>
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}order-metrics`}
                          >
                            <ListItemText primary='Platfom Order - Cleansheet' />
                          </ListItemCustom>
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}extra-invoices`}
                          >
                            <ListItemText primary='Platfom Order - Extra Invoices' />
                          </ListItemCustom>
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}order-issues`}
                            divider
                          >
                            <ListItemText primary='Platfom Order - Order Issues' />
                          </ListItemCustom>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <Can
                    role={user.role}
                    perform='cover:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handleClick('designChallenge')}
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <TypographyCustom type='body2'>
                                Designs Metrics Calculation
                              </TypographyCustom>
                            }
                          />
                          {openDesignChallenges ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openDesignChallenges}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}design-challenges`}
                          >
                            <ListItemText primary='Design Challenge - Turn Over Time' />
                          </ListItemCustom>
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}design-challenges-cards`}
                            divider
                          >
                            <ListItemText primary='Design Challenge - Cards' />
                          </ListItemCustom>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <Can
                    role={user.role}
                    perform='cover:visit'
                    yes={() => (
                      <ListItemCustom
                        selected={'/dashboard' === location.pathname}
                        dense
                        button
                        component={Link}
                        to='/dashboard'
                        divider
                      >
                        <ListItemText primary='Customer Review' />
                      </ListItemCustom>
                    )}
                    no={() => ''}
                  />
                  <ListItemCustom
                    dense
                    button
                    component='a'
                    href={process.env.REACT_APP_URL_ZENTALK}
                    divider
                  >
                    <ListItemText primary='Zendesk Talk Report Cleaner' />
                  </ListItemCustom>
                </List>
              </Collapse>
              <ListItem
                dense
                button
                onClick={(e) => handleClick('book')}
                divider
              >
                <ListItemText
                  disableTypography
                  primary={
                    <TypographyCustom2 type='body2'>
                      Bookkeeper Tools
                    </TypographyCustom2>
                  }
                />
                {openBook ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openBook} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItemCustom
                    dense
                    button
                    component='a'
                    href={process.env.REACT_APP_URL_XERO}
                    divider
                  >
                    <ListItemText primary='Xero Description Generator' />
                  </ListItemCustom>
                </List>
              </Collapse>
              <ListItem
                dense
                button
                onClick={(e) => handleClick('settings')}
                divider
              >
                <ListItemText
                  disableTypography
                  primary={
                    <TypographyCustom2 type='body2'>Settings</TypographyCustom2>
                  }
                />
                {openSettings ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openSettings} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <Can
                    role={user.role}
                    perform='disc:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handleClick('recruitmentSettings')}
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <TypographyCustom type='body2'>
                                Recruitment
                              </TypographyCustom>
                            }
                          />
                          {openRecruitmentSettings ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openRecruitmentSettings}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_JOIN}positions`}
                          >
                            <ListItemText primary='Positions' />
                          </ListItemCustom>
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            divider
                            href={`${process.env.REACT_APP_URL_JOIN}questions`}
                          >
                            <ListItemText primary='Questions' />
                          </ListItemCustom>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <Can
                    role={user.role}
                    perform='cover:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handleClick('coverSettings')}
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <TypographyCustom type='body2'>
                                Order Metrics Calculation
                              </TypographyCustom>
                            }
                          />
                          {openCoverSettings ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse
                          in={openCoverSettings}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}lists`}
                          >
                            <ListItemText primary='Trello Lists' />
                          </ListItemCustom>
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}configuration`}
                            divider
                          >
                            <ListItemText primary='Configuration' />
                          </ListItemCustom>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <Can
                    role={user.role}
                    perform='cover:visit'
                    yes={() => (
                      <Fragment>
                        <ListItem
                          dense
                          button
                          onClick={(e) =>
                            handleClick('designChallengeSettings')
                          }
                          divider
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <TypographyCustom type='body2'>
                                Design Metrics Calculation
                              </TypographyCustom>
                            }
                          />
                          {openDesignChallengeSettings ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openDesignChallengeSettings}
                          timeout='auto'
                          unmountOnExit
                        >
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}design-challenges-lists`}
                          >
                            <ListItemText primary='Trello Lists' />
                          </ListItemCustom>
                          <ListItemCustom
                            dense
                            button
                            component='a'
                            href={`${process.env.REACT_APP_URL_COVER}design-challenges-configuration`}
                            divider
                          >
                            <ListItemText primary='Configuration' />
                          </ListItemCustom>
                        </Collapse>
                      </Fragment>
                    )}
                    no={() => ''}
                  />
                  <ListItemCustom
                    dense
                    button
                    component='a'
                    href={`${process.env.REACT_APP_URL_PAGESPEED}url`}
                    divider
                  >
                    <ListItemText primary='Pagespeed URL' />
                  </ListItemCustom>
                  <Can
                    role={user.role}
                    perform='office:visit'
                    yes={() => (
                      <ListItemCustom
                        dense
                        button
                        component='a'
                        href={`${process.env.REACT_APP_URL_PANEL}offices-ui`}
                      >
                        <ListItemText primary='Office' />
                      </ListItemCustom>
                    )}
                    no={() => ''}
                  />
                  <Can
                    role={user.role}
                    perform='team:visit'
                    yes={() => (
                      <li className='nav-item'>
                        <ListItemCustom
                          dense
                          button
                          component='a'
                          to='/team-ui'
                          href={`${process.env.REACT_APP_URL_PANEL}team-ui`}
                          divider
                        >
                          <ListItemText primary='Team' />
                        </ListItemCustom>
                      </li>
                    )}
                    no={() => ''}
                  />
                </List>
              </Collapse>
              <ListItem
                dense
                button
                onClick={(e) => handleClick('profile')}
                divider
              >
                <ListItemText
                  disableTypography
                  primary={
                    <TypographyCustom2 type='body2'>Profile</TypographyCustom2>
                  }
                />
                {openProfile ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openProfile} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItemCustom
                    dense
                    button
                    component='a'
                    href={`${process.env.REACT_APP_URL_PANEL}profile`}
                  >
                    <ListItemText primary='Profile' />
                  </ListItemCustom>
                  <ListItemCustom
                    dense
                    button
                    component='a'
                    href={process.env.REACT_APP_URL_AUTH + 'logout/'}
                  >
                    <ListItemText primary='Logout' />
                  </ListItemCustom>
                </List>
              </Collapse>
            </RootListCustom>
          </DrawerContainer>
        </DrawerCustom>
      )}
    </Fragment>
  );
};

VerticalNav.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(VerticalNav);
