import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { cacheToLive, clearCache } from '../../utils/cache';

const CardCustom = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const AccordionCustom = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const Cache = ({
  reviews: { reviews, loadingReviews },
  pastReviews: { pastReviews, loadingPastReviews },
  cache,
  clearCache,
}) => {
  const onClickClearCache = () => {
    clearCache();
  };

  const timeNow = dayjs(Date.now());
  const timeCacheReviewCreation = dayjs(cache.review);
  const timeCachePastReviewCreation = dayjs(cache.pastReview);
  const cacheAgeReview = timeNow.diff(timeCacheReviewCreation, 'm');
  const cacheAgePastReview = timeNow.diff(timeCachePastReviewCreation, 'm');
  return (
    <AccordionCustom>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h6'>Cache</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CardCustom variant='outlined'>
          <CardContent>
            <Typography>
              Review data cache will be update in:{' '}
              {cacheToLive - cacheAgeReview} minutes
            </Typography>
            <Typography>
              Past review data cache will be update in:{' '}
              {cacheToLive - cacheAgePastReview} minutes
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size='small'
              variant='contained'
              color='secondary'
              onClick={() => onClickClearCache()}
            >
              clear cache
            </Button>
          </CardActions>
        </CardCustom>
      </AccordionDetails>
    </AccordionCustom>
  );
};

Cache.propTypes = {
  reviews: PropTypes.object.isRequired,
  pastReviews: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
  clearCache: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reviews: state.reviews,
  pastReviews: state.pastReviews,
  cache: state.cache,
});

export default connect(mapStateToProps, { clearCache })(Cache);
