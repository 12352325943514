import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

import Header from './components/layout/Header';
import VerticalNav from './components/layout/VerticalNav';
import Alert from './components/elements/Alert';
import NoDashboard from './components/pages/NoDashboard';
import Dashboard from './components/pages/Dashboard';

import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import store from './store';

function PrivateRoute({ children }) {
  const auth = localStorage.token;
  return auth
    ? children
    : (window.location.href =
        process.env.REACT_APP_URL_AUTH + '?src=' + window.location.href);
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const MainCustom = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

if (window.location.href.includes('?tkn')) {
  let token = window.location.href.split('?tkn=');
  token = token[1];
  localStorage.setItem('token', token);
  setAuthToken(localStorage.token);
}

// if (localStorage.token) {
//   setAuthToken(localStorage.token);
//   store.dispatch(loadUser());
// } else {
//   // window.location.href = config.AUTH_URL + '?src=' + window.location.href;
// }

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Root>
          <CssBaseline />
          <Header />
          <VerticalNav />
          <MainCustom>
            <Toolbar />
            <Alert />
            <Routes>
              <Route
                path='/'
                element={
                  <PrivateRoute>
                    <NoDashboard />
                  </PrivateRoute>
                }
              >
                {/* <Route element={} /> */}
              </Route>
              <Route
                path='/dashboard'
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                {/* <Route element={} /> */}
              </Route>
            </Routes>
          </MainCustom>
        </Root>
      </Router>
    </Provider>
  );
};

export default App;
