import * as dayjs from 'dayjs';
import { REVIEWS } from '../actions/types';

const cacheToLive = process.env.REACT_APP_CACHE_TO_LIVE;

const write = (url, data) => {
  localStorage.setItem(url, JSON.stringify(data));
};

const read = (url) => {
  const data = JSON.parse(localStorage.getItem(url));
  if (!data) {
    return null;
  }
  const timeNow = dayjs(Date.now());
  const timeCacheCreation = dayjs(data.time);
  return timeNow.diff(timeCacheCreation, 'm') > cacheToLive ? null : data;
};

const clearCache = () => {
  var archives = Object.keys(localStorage);
  archives.forEach((archive) => {
    if (archive.includes(REVIEWS)) {
      localStorage.removeItem(archive);
    }
  });
  window.location.reload();
};

export { read, write, cacheToLive, clearCache };
